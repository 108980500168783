<template>
  <div>
    <van-nav-bar
      title="种植历史"
      left-text="返回"
      left-arrow
      @click-left="onClickBank"
    />
    <div class="history_ul">
      <div
        class="history_li"
        v-for="(item, index) in list"
        :key="index"
        @click="handelDetail(item.id)"
      >
        <div class="history_li_left">
          <img :src="item.cropImage" alt="" class="history_li_left_img" />
          <div class="history_li_left_box">
            <div class="plant_name">{{ item.crop_type_name }}</div>
            <div class="cycle">
              {{ item.cultivation_condition_name }}
            </div>
          </div>
        </div>
        <div class="history_li_right">
          <div class="history_li_right_create_data">{{ item.plant_time }}</div>
          <div class="carbon" v-show="item.carbon_num !== 0">
            {{ "碳 " + item.carbon_num }}
          </div>
          <div style="margin-top: 8px">
            <!-- {{
              item.status === 0
                ? currentCropLife
                : item.status === 1
                ? "收获"
                : item.status === 2
                ? "改种"
                : ""
            }} -->
            {{ item.currentCropLife && item.currentCropLife.name ? item.currentCropLife.name : "---" }}
          </div>
          <div class="history_li_right_up_data">
            {{
              item.status === 0
                ? ""
                : item.status === 1
                ? item.status_time.slice(0, 10)
                : item.status === 2
                ? item.status_time.slice(0, 10)
                : ""
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { NavBar } from "vant";
import { getHistory } from "@/service/Addfarmland.js";
export default {
  name: "PlantHistory",
  data() {
    return {
      list: [],
      state: "",
      currentCropLife: ""
    };
  },
  components: {
    VanNavBar: NavBar
  },
  mounted() {
    this.getHistoryData();
  },
  methods: {
    onClickBank() {
      this.$router.go(-1);
    },
    getHistoryData() {
      const id = this.$route.params && this.$route.params.id;
      getHistory(id).then((res) => {
        this.list = res.data;
        for (var i = 0; i < res.data.length; i++) {
          if (res.data[i].status === 0) {
            if (res.data[i].currentCropLife === null) {
              return;
            } else {
              this.currentCropLife = res.data[i].currentCropLife.name;
            }
          }
        }
      });
    },
    handelDetail(id) {
      this.$router.push({
        path: `/historydetail/${id}`
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.history_ul {
  margin-left: 10px;
  margin-top: 10px;
  margin-right: 10px;
  .history_li {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 25px;
    margin-bottom: 10px;
    justify-content: space-between;
    .history_li_left {
      margin-left: 10px;
      display: flex;
      .history_li_left_img {
        padding-top: 10px;
        padding-bottom: 10px;
        width: 90px;
        height: 90px;
      }
      .history_li_left_box {
        padding-top: 25px;
        padding-left: 10px;
        .plant_name {
          padding-bottom: 10px;
        }
        .cycle {
          padding-top: 10px;
        }
      }
    }
    .history_li_right {
      padding-top: 25px;
      padding-right: 14px;
      position: relative;
      /* display: flex;
      flex-direction: column;
      align-items: center; */
      .history_li_right_create_data {
        padding-bottom: 10px;
      }
      .history_li_right_up_data {
        padding-top: 10px;
      }
      .carbon {
        background: #d0e17d;
        padding: 0 8px;
        display: inline-block;
      }
    }
  }
}
</style>
